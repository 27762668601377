import React, { useState } from 'react';
import { Paper, Box, Typography, Button, TextField } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import watchicon from '../../Assets/mainPage/watchicon.svg';
import Header from '../HeaderUi/Header';
import * as Styles from '../../Common/Styles.js';
import Footer from '../HomeChat/Footer.jsx';
import messageicon from '../../Assets/ProfileImg/messageicon.svg';
import locationicon from '../../Assets/ProfileImg/locationicon.svg';
import Fade from 'react-reveal/Fade';
import axios from 'axios';
const ContactUs = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleSubmit = () => {
  //   const { fullName, email, message } = formData;
  //   if (!fullName || !email || !message) {
  //     toast.error('Please fill in all fields.');
  //   } else {
  //     // Handle form submission
  //     console.log('Form submitted:', formData);
  //   }
  // };

  const [firstname, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setFirstName('');
    setEmail('');
    setMessage('');
    if (!firstname || !email || !message) {
      toast.error('Please fill in all fields');
      return;
    }
    return await axios
      .post('/', {
        FirstName: firstname,
        Email: email,
        Message: message,
      })
      .then((res) => {
        toast.success('submitted successfully');
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        toast.error('something went wrong');
      });
  };

  return (
    <>
      <ToastContainer />
      <Paper elevation={0}>
        <Box
          sx={{
            position: 'relative',
            height: '30vh',
            background: '#F5F5F7',
          }}
        >
          <Box
            sx={{
              zIndex: 1,
              width: '100%',
              position: 'absolute',
            }}
          >
            <Header />
            <Fade top duration={1000}>
              <Typography sx={{ ...Styles.headHeading, mt: 12 }}>
                Contact Us
              </Typography>
            </Fade>
          </Box>
        </Box>
        <Box sx={setUpConnect}>
          <Fade left duration={1000}>
            <Box>
              <Typography sx={{ ...contactText, fontSize: '16px' }}>
                Let’s Connect
              </Typography>
              <Typography sx={contactText}>
                <Box
                  component={'img'}
                  src={messageicon}
                  sx={{ width: 'auto', height: 'auto' }}
                />
                email : business@unada.io
              </Typography>
              <Typography sx={{ ...contactText, lineHeight: '20px' }}>
                <Box
                  component={'img'}
                  src={locationicon}
                  sx={{ width: 'auto', height: 'auto' }}
                />
                Address : Unada LexiLegal AI India Private Limited 550, Iscon
                Emporio, Jodhpur Char Rasta, Ahmedabad - 380015
              </Typography>
              <Box sx={backText}>
                <Box
                  component={'img'}
                  src={watchicon}
                  alt=""
                  sx={{ width: 'auto', height: 'auto' }}
                />
                Responding 24 hours a day, 7 days a week
              </Box>
            </Box>
          </Fade>
          <Box
            sx={{
              width: { xl: '60%', lg: '60%', md: '60%', sm: '80%', xs: '80%' },
              mt: { xl: 0, lg: 0, md: 0, sm: 5, xs: 5 },
            }}
          >
            <form
              action="https://formsubmit.co/shrikant@unada.io"
              method="POST"
            >
              <input type="hidden" name="_captcha" value="false" />
              {/* <input type="hidden" name="_next" value="https://parasors.in/" /> */}
              <Fade right duration={1000}>
                <Box>
                  <Typography sx={textfeildtext}>Full Name:</Typography>
                  <TextField
                    id="fullName"
                    name="fullName"
                    size="small"
                    variant="standard"
                    sx={textfeilddesign}
                    value={formData.fullName}
                    onChange={handleInputChange}
                    required
                    onInvalid={(e) =>
                      e.target.setCustomValidity('Please enter your full name.')
                    }
                    onInput={(e) => e.target.setCustomValidity('')}
                  />
                </Box>
                <Box mt={1}>
                  <Typography sx={textfeildtext}>Email:</Typography>
                  <TextField
                    id="email"
                    name="email"
                    size="small"
                    variant="standard"
                    sx={textfeilddesign}
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    type="email"
                    onInvalid={(e) =>
                      e.target.setCustomValidity(
                        'Please enter a valid email address.'
                      )
                    }
                    onInput={(e) => e.target.setCustomValidity('')}
                  />
                </Box>
                <Box mt={1}>
                  <Typography sx={textfeildtext}>Message:</Typography>
                  <TextField
                    id="message"
                    name="message"
                    size="small"
                    multiline
                    rows={3}
                    variant="standard"
                    sx={textfeilddesign}
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                    onInvalid={(e) =>
                      e.target.setCustomValidity('Please enter your message.')
                    }
                    onInput={(e) => e.target.setCustomValidity('')}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: {
                      xl: 'flex-start',
                      lg: 'flex-start',
                      md: 'flex-start',
                      sm: 'center',
                      xs: 'center',
                    },
                    alignItems: 'center',
                  }}
                >
                  <Button
                    type="submit"
                    sx={{
                      background: '#091A32',
                      color: '#fff',
                      borderRadius: '12px',
                      mt: 2,
                      textTransform: 'none',
                      width: '120px',
                      '&:hover': {
                        background: '#091A32',
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Fade>
            </form>
          </Box>
        </Box>
        <Box sx={{}}>
          <iframe
            scrolling="no"
            style={{
              border: 'none',
            }}
            width="100%"
            height="290px"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=Unada Labs Pvt Ltd, Jodhpur Char Rasta, Satellite, Ahmedabad, Gujarat, India&t=&z=10&ie=UTF8&iwloc=&output=embed"
          ></iframe>
        </Box>
      </Paper>
      <Footer />
    </>
  );
};

export default ContactUs;

const textfeilddesign = {
  width: '100%',
  '& .MuiInput-underline:before': {
    borderBottomColor: 'secondary.main', // Normal state
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: 'primary.main', // Hover state
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'secondary.main', // Focused state
  },
};

const textfeildtext = {
  fontFamily: 'Manrope',
  fontSize: { xl: '12px', lg: '12px', md: '11px', sm: '10px', xs: '10px' },
  fontWeight: 400,
  textAlign: 'left',
  color: 'rgba(0, 0, 0, 1)',
};

const backText = {
  background: '#000',
  color: '#fff',
  p: 1,
  borderRadius: '12px',
  fontFamily: 'Manrope',
  fontSize: { xl: '13px', lg: '13px', md: '11px', sm: '11px', xs: '11px' },
  fontWeight: 500,
  textAlign: 'left',
  display: 'flex',
  width: '60%',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 1,
  mt: 2,
};

const contactText = {
  fontFamily: 'DM Sans',
  fontSize: { xl: '13px', lg: '13px', md: '11px', sm: '11px', xs: '11px' },
  fontWeight: 600,
  textAlign: { xl: 'left', lg: 'left', md: 'left', sm: 'center', xs: 'center' },
  color: 'rgba(62, 50, 50, 1)',
  lineHeight: '30px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
};

const setUpConnect = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: {
    xl: 'row',
    lg: 'row',
    md: 'column',
    sm: 'column',
    xs: 'column',
  },
  width: { xl: '60%', lg: '60%', md: '90%', sm: '90%', xs: '90%' },
  gap: '10px',
  mx: 'auto',
  mt: 4,
  p: { xl: 7, lg: 7, md: 5, sm: 2, xs: 2 },
};

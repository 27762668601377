import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { GetTokenFunc } from "../utils/GetToken";

export const useGetBlogs = () => {
  return useQuery({
    queryKey: ["userBlogs"],
    queryFn: async () => {
      return await axios.get("api/v1/blog", {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};
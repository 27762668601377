import React, { useEffect, useState } from 'react';
import { Box, Typography, styled, Button, Grid, Input } from '@mui/material';
import { useApplyCoupon, useGetWallet } from '../../Hooks/wallet.hooks';
import * as Styles from '../../Common/Styles.js';
import { toast } from 'react-toastify';
import QRcode from '../../Assets/Homeimg/QRcode.png';
// import StudentPlanQR from '../../Assets/Homeimg/StudentPlanQR.png'; // Add the correct path
// import JudgePlanQR from '../../Assets/Homeimg/JudgePlanQR.png'; // Add the correct path

const QRCodeStaticScanner = () => {
  const [couponCode, setCouponCode] = useState('');
  const { mutate: applyCoupon } = useApplyCoupon();

  const {
    data: walletData,
    isLoading: walletLoading,
    error: walletError,
    refetch: walletDataRefetch,
  } = useGetWallet();

  const handleApplyCoupon = () => {
    setCouponCode('');
    if (couponCode) {
      applyCoupon(
        { couponCode },
        {
          onSuccess: () => {
            toast.success('Coupon applied successfully');
            walletDataRefetch();
          },
        }
      );
    }
  };

  const [tab, setTab] = useState(0);
  const [tabDate, setTabDate] = useState(0);
  const [tabDateTopUp, setTabDateTopUp] = useState(0);

  return (
    <>
      <Grid
        container
        rowSpacing={2}
        sx={{
          width: '90%',
          mx: 'auto',
          mt: 2,
        }}
      >
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
          <Box
            sx={{
              border: '1px solid rgba(230, 233, 245, 1)',
              borderRadius: '10px',
              maxHeight: 'auto',
              minWidth: {
                xl: '330px',
                lg: '330px',
                md: '300px',
                sm: '300px',
                xs: '300px',
              },
              maxWidth: '230px',
              p: 2,
            }}
          >
            <Typography
              sx={{
                ...Styles.gridheaderText,
                color: 'rgba(62, 50, 50, 1)',
                textAlign: 'left',
                fontWeight: 700,
                ml: { xl: 1, lg: 1, md: 1, sm: 0, xs: 0 },
              }}
            >
              Choose Plan
            </Typography>
            <Box
              sx={{
                ...Styles.categoriesBox,
                background: tab === 0 ? '#091A32' : 'rgba(247, 249, 251, 1)',
                color:
                  tab === 0 ? 'rgba(255, 255, 255, 1)' : 'rgba(62, 50, 50, 1)',
              }}
              onClick={() => setTab(0)}
            >
              <Typography>General Plan</Typography>
            </Box>
            <Box
              sx={{
                ...Styles.categoriesBox,
                background: tab === 1 ? '#091A32' : 'rgba(247, 249, 251, 1)',
                color:
                  tab === 1 ? 'rgba(255, 255, 255, 1)' : 'rgba(62, 50, 50, 1)',
              }}
              onClick={() => setTab(1)}
            >
              <Typography>Student Plan</Typography>
            </Box>
            <Box
              sx={{
                ...Styles.categoriesBox,
                background: tab === 2 ? '#091A32' : 'rgba(247, 249, 251, 1)',
                color:
                  tab === 2 ? 'rgba(255, 255, 255, 1)' : 'rgba(62, 50, 50, 1)',
              }}
              onClick={() => setTab(2)}
            >
              <Typography>Top Up Plan</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid xl={0.5} lg={0.5} md={1} sm={12} xs={12}></Grid>
        <Grid
          item
          xl={8.5}
          lg={8.5}
          md={8}
          sm={12}
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <Box sx={mainBox}>
            {tab === 0 ? (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
              >
                <Typography sx={Styles.gridheaderText}>
                  You Choose General Plan
                </Typography>
                <Box
                  component={'hr'}
                  sx={{
                    borderBottom: '1px solid rgba(209, 209, 209, 1)',
                    width: '100%',
                  }}
                />
                <Box sx={btnWidth}>
                  <Box
                    sx={{
                      ...Styles.planBoxButton,
                      background:
                        tabDate === 0 ? '#091A32' : 'rgba(247, 249, 251, 1)',
                      color:
                        tabDate === 0
                          ? 'rgba(255, 255, 255, 1)'
                          : 'rgba(62, 50, 50, 1)',
                    }}
                    onClick={() => setTabDate(0)}
                  >
                    Monthly Plan
                  </Box>
                  <Box
                    sx={{
                      ...Styles.planBoxButton,
                      background:
                        tabDate === 1 ? '#091A32' : 'rgba(247, 249, 251, 1)',
                      color:
                        tabDate === 1
                          ? 'rgba(255, 255, 255, 1)'
                          : 'rgba(62, 50, 50, 1)',
                    }}
                    onClick={() => setTabDate(1)}
                  >
                    Yearly Plan
                  </Box>
                </Box>
                {tabDate === 0 ? (
                  <>
                    <Box sx={sizeAdj}>
                      <Box>
                        <Typography
                          variant="h6" // Set header as a more prominent size
                          sx={{
                            ...Styles.gridheaderText,
                            textAlign: 'left',
                            fontWeight: 'bold',
                          }}
                        >
                          Company Details
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Company Name:{' '}
                          <strong>
                            UNADA LEXILEGAL AI INDIA PRIVATE LIMITED
                          </strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Account Number: <strong>10166717117</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          IFSC: <strong>IDFB0040346</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          SWIFT Code: <strong>IDFBINBBMUM</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Bank Name: <strong>IDFC FIRST</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Branch: <strong>AHMEDABAD - MAKARBA BRANCH</strong>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                          ml: {
                            xl: '100px',
                            lg: '100px',
                            md: '100px',
                            sm: '0px',
                            xs: '0px',
                          }, // Align subhead texts properly
                        }}
                      >
                        <Typography
                          variant="h6" // Header for the benefits section
                          sx={{
                            ...Styles.gridheaderText,
                            textAlign: 'left',
                            fontWeight: 'bold',
                          }}
                        >
                          Benefits
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Price:{' '}
                          <strong
                            style={{
                              fontWeight: 'bold',
                              fontSize: '14px',
                              color: '#000',
                            }}
                          >
                            650/-{' '}
                          </strong>
                          per month
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Credits: 65
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Validity: 28 days
                        </Typography>
                      </Box>
                    </Box>
                  </>
                ) : null}
                {tabDate === 1 ? (
                  <>
                    <Box sx={sizeAdj}>
                      <Box>
                        <Typography
                          variant="h6" // Set header as a more prominent size
                          sx={{
                            ...Styles.gridheaderText,
                            textAlign: 'left',
                            fontWeight: 'bold',
                          }}
                        >
                          Company Details
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Company Name:{' '}
                          <strong>
                            UNADA LEXILEGAL AI INDIA PRIVATE LIMITED
                          </strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Account Number: <strong>10166717117</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          IFSC: <strong>IDFB0040346</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          SWIFT Code: <strong>IDFBINBBMUM</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Bank Name: <strong>IDFC FIRST</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Branch: <strong>AHMEDABAD - MAKARBA BRANCH</strong>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                          ml: {
                            xl: '100px',
                            lg: '100px',
                            md: '100px',
                            sm: '0px',
                            xs: '0px',
                          }, // Align subhead texts properly
                        }}
                      >
                        <Typography
                          variant="h6" // Header for the benefits section
                          sx={{
                            ...Styles.gridheaderText,
                            textAlign: 'left',
                            fontWeight: 'bold',
                          }}
                        >
                          Benefits
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Price:{' '}
                          <strong
                            style={{
                              fontWeight: 'bold',
                              fontSize: '14px',
                              color: '#000',
                            }}
                          >
                            5999/-{' '}
                          </strong>
                          per month
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Credits: 900
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Validity: 365 days
                        </Typography>
                      </Box>
                    </Box>
                  </>
                ) : null}
              </Box>
            ) : null}
            {tab === 1 ? (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
              >
                <Typography sx={Styles.gridheaderText}>
                  You Choose Student Plan
                </Typography>
                <Box
                  component={'hr'}
                  sx={{
                    borderBottom: '1px solid rgba(209, 209, 209, 1)',
                    width: '100%',
                  }}
                />
                <Box sx={sizeAdj}>
                  {/* <Box
                    component={'img'}
                    src={QRcode}
                    alt="Student Plan QR Code"
                    sx={imgqr}
                  /> */}
                  <Box>
                    <Typography
                      variant="h6" // Set header as a more prominent size
                      sx={{
                        ...Styles.gridheaderText,
                        textAlign: 'left',
                        fontWeight: 'bold',
                      }}
                    >
                      Company Details
                    </Typography>
                    <Typography
                      sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                    >
                      Company Name:{' '}
                      <strong>UNADA LEXILEGAL AI INDIA PRIVATE LIMITED</strong>
                    </Typography>
                    <Typography
                      sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                    >
                      Account Number: <strong>10166717117</strong>
                    </Typography>
                    <Typography
                      sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                    >
                      IFSC: <strong>IDFB0040346</strong>
                    </Typography>
                    <Typography
                      sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                    >
                      SWIFT Code: <strong>IDFBINBBMUM</strong>
                    </Typography>
                    <Typography
                      sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                    >
                      Bank Name: <strong>IDFC FIRST</strong>
                    </Typography>
                    <Typography
                      sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                    >
                      Branch: <strong>AHMEDABAD - MAKARBA BRANCH</strong>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      ml: {
                        xl: '100px',
                        lg: '100px',
                        md: '100px',
                        sm: '0px',
                        xs: '0px',
                      }, // Align subhead texts properly
                    }}
                  >
                    <Typography
                      variant="h6" // Header for the benefits section
                      sx={{
                        ...Styles.gridheaderText,
                        textAlign: 'left',
                        fontWeight: 'bold',
                      }}
                    >
                      Benefits
                    </Typography>
                    <Typography
                      sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                    >
                      Price:{' '}
                      <strong
                        style={{
                          fontWeight: 'bold',
                          fontSize: '14px',
                          color: '#000',
                        }}
                      >
                        2500/-{' '}
                      </strong>
                      per month
                    </Typography>
                    <Typography
                      sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                    >
                      Credits: 200
                    </Typography>
                    <Typography
                      sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                    >
                      Validity: 365 days
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ) : null}
            {tab === 2 ? (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
              >
                <Typography sx={Styles.gridheaderText}>
                  You Choose Top-up Plan
                </Typography>
                <Box
                  component={'hr'}
                  sx={{
                    borderBottom: '1px solid rgba(209, 209, 209, 1)',
                    width: '100%',
                  }}
                />
                <Box sx={{ ...btnWidth, overflowX: 'scroll' }}>
                  <Box
                    sx={{
                      ...Styles.planBoxButton,
                      background:
                        tabDateTopUp === 0
                          ? '#091A32'
                          : 'rgba(247, 249, 251, 1)',
                      color:
                        tabDateTopUp === 0
                          ? 'rgba(255, 255, 255, 1)'
                          : 'rgba(62, 50, 50, 1)',
                    }}
                    onClick={() => setTabDateTopUp(0)}
                  >
                    Plan 1
                  </Box>
                  <Box
                    sx={{
                      ...Styles.planBoxButton,
                      background:
                        tabDateTopUp === 1
                          ? '#091A32'
                          : 'rgba(247, 249, 251, 1)',
                      color:
                        tabDateTopUp === 1
                          ? 'rgba(255, 255, 255, 1)'
                          : 'rgba(62, 50, 50, 1)',
                    }}
                    onClick={() => setTabDateTopUp(1)}
                  >
                    Plan 2
                  </Box>
                  <Box
                    sx={{
                      ...Styles.planBoxButton,
                      background:
                        tabDateTopUp === 2
                          ? '#091A32'
                          : 'rgba(247, 249, 251, 1)',
                      color:
                        tabDateTopUp === 2
                          ? 'rgba(255, 255, 255, 1)'
                          : 'rgba(62, 50, 50, 1)',
                    }}
                    onClick={() => setTabDateTopUp(2)}
                  >
                    Plan 3
                  </Box>
                  <Box
                    sx={{
                      ...Styles.planBoxButton,
                      background:
                        tabDateTopUp === 3
                          ? '#091A32'
                          : 'rgba(247, 249, 251, 1)',
                      color:
                        tabDateTopUp === 3
                          ? 'rgba(255, 255, 255, 1)'
                          : 'rgba(62, 50, 50, 1)',
                    }}
                    onClick={() => setTabDateTopUp(3)}
                  >
                    Plan 4
                  </Box>
                </Box>
                {tabDateTopUp === 0 ? (
                  <>
                    <Box sx={sizeAdj}>
                      <Box>
                        <Typography
                          variant="h6" // Set header as a more prominent size
                          sx={{
                            ...Styles.gridheaderText,
                            textAlign: 'left',
                            fontWeight: 'bold',
                          }}
                        >
                          Company Details
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Company Name:{' '}
                          <strong>
                            UNADA LEXILEGAL AI INDIA PRIVATE LIMITED
                          </strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Account Number: <strong>10166717117</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          IFSC: <strong>IDFB0040346</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          SWIFT Code: <strong>IDFBINBBMUM</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Bank Name: <strong>IDFC FIRST</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Branch: <strong>AHMEDABAD - MAKARBA BRANCH</strong>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                          ml: {
                            xl: '100px',
                            lg: '100px',
                            md: '100px',
                            sm: '0px',
                            xs: '0px',
                          }, // Align subhead texts properly
                        }}
                      >
                        <Typography
                          variant="h6" // Header for the benefits section
                          sx={{
                            ...Styles.gridheaderText,
                            textAlign: 'left',
                            fontWeight: 'bold',
                          }}
                        >
                          Benefits
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Price:{' '}
                          <strong
                            style={{
                              fontWeight: 'bold',
                              fontSize: '14px',
                              color: '#000',
                            }}
                          >
                            350/-{' '}
                          </strong>
                          per month
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Credits: 65
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Validity: 28 days
                        </Typography>
                      </Box>
                    </Box>
                  </>
                ) : null}
                {tabDateTopUp === 1 ? (
                  <>
                    <Box sx={sizeAdj}>
                      <Box>
                        <Typography
                          variant="h6" // Set header as a more prominent size
                          sx={{
                            ...Styles.gridheaderText,
                            textAlign: 'left',
                            fontWeight: 'bold',
                          }}
                        >
                          Company Details
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Company Name:{' '}
                          <strong>
                            UNADA LEXILEGAL AI INDIA PRIVATE LIMITED
                          </strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Account Number: <strong>10166717117</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          IFSC: <strong>IDFB0040346</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          SWIFT Code: <strong>IDFBINBBMUM</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Bank Name: <strong>IDFC FIRST</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Branch: <strong>AHMEDABAD - MAKARBA BRANCH</strong>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                          ml: {
                            xl: '100px',
                            lg: '100px',
                            md: '100px',
                            sm: '0px',
                            xs: '0px',
                          }, // Align subhead texts properly
                        }}
                      >
                        <Typography
                          variant="h6" // Header for the benefits section
                          sx={{
                            ...Styles.gridheaderText,
                            textAlign: 'left',
                            fontWeight: 'bold',
                          }}
                        >
                          Benefits
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Price:{' '}
                          <strong
                            style={{
                              fontWeight: 'bold',
                              fontSize: '14px',
                              color: '#000',
                            }}
                          >
                            1000/-{' '}
                          </strong>
                          per month
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Credits: 900
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Validity: 365 days
                        </Typography>
                      </Box>
                    </Box>
                  </>
                ) : null}
                {tabDateTopUp === 2 ? (
                  <>
                    <Box sx={sizeAdj}>
                      <Box>
                        <Typography
                          variant="h6" // Set header as a more prominent size
                          sx={{
                            ...Styles.gridheaderText,
                            textAlign: 'left',
                            fontWeight: 'bold',
                          }}
                        >
                          Company Details
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Company Name:{' '}
                          <strong>
                            UNADA LEXILEGAL AI INDIA PRIVATE LIMITED
                          </strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Account Number: <strong>10166717117</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          IFSC: <strong>IDFB0040346</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          SWIFT Code: <strong>IDFBINBBMUM</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Bank Name: <strong>IDFC FIRST</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Branch: <strong>AHMEDABAD - MAKARBA BRANCH</strong>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                          ml: {
                            xl: '100px',
                            lg: '100px',
                            md: '100px',
                            sm: '0px',
                            xs: '0px',
                          }, // Align subhead texts properly
                        }}
                      >
                        <Typography
                          variant="h6" // Header for the benefits section
                          sx={{
                            ...Styles.gridheaderText,
                            textAlign: 'left',
                            fontWeight: 'bold',
                          }}
                        >
                          Benefits
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Price:{' '}
                          <strong
                            style={{
                              fontWeight: 'bold',
                              fontSize: '14px',
                              color: '#000',
                            }}
                          >
                            2000/-{' '}
                          </strong>
                          per month
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Credits: 900
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Validity: 365 days
                        </Typography>
                      </Box>
                    </Box>
                  </>
                ) : null}
                {tabDateTopUp === 3 ? (
                  <>
                    <Box sx={sizeAdj}>
                      <Box>
                        <Typography
                          variant="h6" // Set header as a more prominent size
                          sx={{
                            ...Styles.gridheaderText,
                            textAlign: 'left',
                            fontWeight: 'bold',
                          }}
                        >
                          Company Details
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Company Name:{' '}
                          <strong>
                            UNADA LEXILEGAL AI INDIA PRIVATE LIMITED
                          </strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Account Number: <strong>10166717117</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          IFSC: <strong>IDFB0040346</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          SWIFT Code: <strong>IDFBINBBMUM</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Bank Name: <strong>IDFC FIRST</strong>
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Branch: <strong>AHMEDABAD - MAKARBA BRANCH</strong>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                          ml: {
                            xl: '100px',
                            lg: '100px',
                            md: '100px',
                            sm: '0px',
                            xs: '0px',
                          }, // Align subhead texts properly
                        }}
                      >
                        <Typography
                          variant="h6" // Header for the benefits section
                          sx={{
                            ...Styles.gridheaderText,
                            textAlign: 'left',
                            fontWeight: 'bold',
                          }}
                        >
                          Benefits
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Price:{' '}
                          <strong
                            style={{
                              fontWeight: 'bold',
                              fontSize: '14px',
                              color: '#000',
                            }}
                          >
                            6000/-{' '}
                          </strong>
                          per month
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Credits: 900
                        </Typography>
                        <Typography
                          sx={{ ...Styles.uploadSubText, textAlign: 'left' }}
                        >
                          Validity: 365 days
                        </Typography>
                      </Box>
                    </Box>
                  </>
                ) : null}
              </Box>
            ) : null}
            <Typography mt={1} sx={Styles.uploadSubText}>
              Scan the QR code and make the payment after contacting this email:
              support@parasorc.in
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 1,
                gap: 1,
              }}
            >
              <Input
                id="standard-basic"
                variant="outlined"
                disableUnderline
                placeholder="Apply Coupon"
                onChange={(e) => setCouponCode(e.target.value)}
                sx={{
                  ...Styles.InputFieldDes,
                  border: '1px solid rgba(209, 209, 209, 1)',
                }}
                inputProps={{
                  style: {
                    color: '#797979',
                    fontSize: '14px',
                    padding: '4px',
                    paddingLeft: '10px', // Adjust the left padding to make room for the icon
                  },
                }}
              />
              <Button onClick={handleApplyCoupon}>Apply</Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default QRCodeStaticScanner;

const imgqr = {
  height: 'auto',
  maxWidth: {
    xl: '150px',
    lg: '150px',
    md: '150px',
    sm: '120px',
    xs: '120px',
  },
};

const mainBox = {
  border: '1px solid rgba(230, 233, 245, 1)',
  borderRadius: '10px',
  width: {
    xl: '900px',
    lg: '700px',
    md: '500px',
    sm: 'auto',
    xs: 'auto',
  },
  height: 'auto',
  p: 3,
};

const btnWidth = {
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  width: {
    xl: '60%',
    lg: '60%',
    md: '60%',
    sm: '95%',
    xs: '95%',
  },
  mx: 'auto',
  textAlign: 'center',
};

const sizeAdj = {
  display: 'flex',
  flexDirection: {
    xl: 'row',
    lg: 'row',
    md: 'row',
    sm: 'column',
    xs: 'column',
  },
  gap: { xl: '100px', lg: '100px', md: '100px', sm: '30px', xs: '30px' },
  mt: 1,
};

import { px } from "framer-motion";

export const PaperCommonDesReg = {
  display: "flex",
  minHeight: "100vh",
  minWidth: "100vw",
  overflow: "hidden",
  background:
    "linear-gradient(219deg, #EBEBEF -11.5%, #F9F9FA 31.72%, rgba(255, 255, 255, 0.90) 32.22%, rgba(240, 241, 241, 0.02) 40.58%, rgba(245, 246, 246, 0.14) 57.06%, #D7D7D7 115.43%)",
};
export const PaperDesOther = {
  minHeight: "100vh",
  minWidth: "100vw",
  background:
    "linear-gradient(219deg, #EBEBEF -11.5%, #F9F9FA 31.72%, rgba(255, 255, 255, 0.90) 32.22%, rgba(240, 241, 241, 0.02) 40.58%, rgba(245, 246, 246, 0.14) 57.06%, #D7D7D7 115.43%)",
};
export const chatText = {
  color: "#191919",
  textAlign: "right",
  fontFamily: "Manrope",
  fontSize: { xl: "1.8rem", lg: "1.8rem", md: "1.5rem", sm: "1rem" },
  fontStyle: "normal",
  fontWeight: 700,
  display: "flex",
  justifyContent: "flex-end",
  width: "75%",
};

export const chatSub = {
  color: "rgba(0, 0, 0, 0.50)",
  textAlign: "right",
  fontFamily: "Manrope",
  fontSize: {
    xl: "1.2rem",
    lg: "1.2rem",
    md: "1rem",
    sm: "0.8rem",
    xs: "0.8rem",
  },
  fontStyle: "normal",
  fontWeight: 400,
  width: "75%",
};

export const subText = {
  color: "#000",
  fontFamily: "Manrope",
  fontSize: {
    xl: "1rem",
    lg: "1rem",
    md: "1rem",
    sm: "0.7rem",
    xs: "0.7rem",
  },
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  mr: "auto",
};

export const iconStyle = {
  height: { xl: "20px", lg: "20px", lg: "18px", sm: "15px", xs: "15px" },
  width: { xl: "20px", lg: "20px", lg: "18px", sm: "15px", xs: "15px" },
};

export const signInBtn = {
  borderRadius: "10px",
  backgroundColor: "primary.main",
  color: "#FFF",
  fontFamily: "Manrope",
  fontSize: { xl: "1rem", lg: "1rem", md: "1rem", sm: "0.7rem", xs: "0.7rem" },
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textTransform: "none",
  p: { xl: 2, lg: 2, md: 2, sm: 1.5, xs: 1.5 },
  // mt: { xl: 1.5, lg: 1.5, md: 1.5, sm: 1, xs: 1 },
  "&:hover": {
    backgroundColor: "primary.light",
  },
};

export const regButton = {
  width: "auto",
  height: "auto",
  borderRadius: "0.8rem",
  p: 1,
  border: "1px solid rgba(209, 209, 209, 1)",
  fontFamily: "Manrope",
  fontSize: {
    xl: "1rem",
    lg: "1rem",
    md: "1rem",
    sm: "0.6rem",
    xs: "0.6rem",
  },
  fontWeight: 400,
  color: "rgba(121, 121, 121, 1)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
};

export const paperDes = {
  width: { xl: "100%", lg: "100%", md: "100%", sm: "95%", xs: "95%" },
  minHeight: {
    xl: "500px",
    lg: "500px",
    md: "400px",
    sm: "300px",
    xs: "250px",
  },
  height: "auto",
  flexShrink: 0,
  borderRadius: { xl: "45px", lg: "45px", md: "40px", sm: "30px", xs: "20px" },
  background: "#FFF",
  boxShadow: "0px 10px 20px 0px rgba(147, 147, 147, 0.25)",
  p: 1,
  zIndex: 1,
  marginTop: "10px",
  marginLeft: {
    xl: "-30px",
    lg: "-30px",
    md: "-30px",
    sm: "-10px",
    xs: "-10px",
  },
  position: "absoulte",
  transform: "rotate(6deg) translateY(0px)",
};

export const paperBack = {
  width: { xl: "30%", lg: "30%", md: "70%", sm: "80%", xs: "80%" },
  // minHeight: {
  //   xl: '350px',
  //   lg: '350px',
  //   md: '300px',
  //   sm: '200px',
  //   xs: '150px',
  // },
  height: "auto",
  flexShrink: 0,
  borderRadius: { xl: "45px", lg: "45px", md: "40px", sm: "30px", xs: "20px" },
  background: "#E7E7E8",
  boxShadow: "0px 10px 20px 0px rgba(147, 147, 147, 0.25)",
  p: 0,
  zIndex: 0,
  marginLeft: { xl: "50px", lg: "50px", md: "50px", sm: "0px", xs: "5px" },
  transform: "rotate(-6deg)",
  position: "relative",
  // mt: { xl: 0, lg: 0, md: 0, sm: -20, xs: -20 },
  // mb: { xl: 0, lg: 0, md: 0, sm: '40px', xs: '40px' },
};

export const signUp = {
  color: "#191919",
  textAlign: "center",
  fontFamily: "Manrope",
  fontSize: { xl: "20px", lg: "20px", md: "16px", sm: "14px", xs: "14px" },
  fontStyle: "normal",
  fontWeight: 700,
};

export const LogInBtn = {
  borderRadius: "10px",
  border: "0.996px solid #E9E9E9",
  color: "#797979",
  fontFamily: "Manrope",
  fontSize: { xl: "1rem", lg: "1rem", md: "1rem", sm: "0.8rem", xs: "0.8rem" },
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textTransform: "none",
  p: { xl: 1.5, lg: 1.5, md: 1.5, sm: 1, xs: 1 },
  mt: { xl: 2, lg: 2, md: 2, sm: 1, xs: 1 },
  cursor: "pointer",
};

export const forgetText = {
  fontFamily: "Manrope",
  fontSize: {
    xl: "0.8rem",
    lg: "0.8rem",
    md: "0.8rem",
    sm: "0.7rem",
    xs: "0.7rem",
  },
  fontWeight: 500,
  textAlign: "right",
  color: "rgba(0, 0, 0, 1)",
};

export const welText = {
  color: "#191919",
  textAlign: "center",
  fontFamily: "Manrope",
  fontSize: { xl: "2rem", lg: "2rem", md: "2rem", sm: "1rem", xs: "1rem" },
  fontStyle: "normal",
  fontWeight: 700,
};

export const chatTextDesGrid = {
  display: "flex",
  justifyContent: {
    xl: "center",
    lg: "center",
    md: "center",
    sm: "center",
    xs: "center",
  },
  alignItems: {
    xl: "center",
    lg: "center",
    md: "center",
    sm: "center",
    xs: "center",
  },
  p: 1,
  mt: 2,
  flexDirection: "column",
  height: { xl: "85%", lg: "85%", md: "85%", sm: "32%", xs: "45%" },
};

export const PaperGrid = {
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  marginBottom: {
    xl: "0px",
    lg: "0px",
    md: "60px",
    sm: "40px",
    xs: "20px",
  },
};

export const InputFieldDes = {
  width: "auto",
  height: {
    xl: "38px",
    lg: "38px",
    md: "36px",
    sm: "30px",
    xs: "29px",
  },
  background: "#FFFFFF",
  borderRadius: {
    xl: "10px",
    lg: "10px",
    md: "9px",
    sm: "8px",
    xs: "8px",
  },
  fontSize: "12px",
  color: "#445FD2",
  px: 1,
  border: "1px solid rgba(209, 209, 209, 1)",
  "&::placeholder": {
    color: "#445FD2", // Change the color here
  },
};

// validaation style

export const validationText = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  color: "red",
};

// Chat pdf style

export const newChat = {
  borderRadius: "10px",
  border: "1px solid #E8E8E8",
  width: "65%",
  mx: "auto",
  height: { xl: "40px", lg: "40px", md: "45px", sm: "45px", xs: "45px" },
  flexShrink: "0",
  color: "#fff",
  textAlign: "center",
  fontFamily: "Manrope",
  fontSize: { xl: "16px", lg: "15px", md: "13px", sm: "14px", xs: "14px" },
  fontStyle: "normal",
  fontWeight: 400,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  cursor: "pointer",
  mt: { xl: 0, lg: 0, md: 1, sm: 0, xs: 1 },
  // transition: ' transform 0.3s',
  transition: "border-color 0.3s ",
  // background: 'rgba(86, 97, 246, 1)',
  background: "#091A32",

  "&:hover": {
    // background: '#E8E8E8',
    // transform: 'scale(1.05)',
    // borderColor: '#605BFF',
    border: "1.5px solid #fff",
  },
};

// export const LogOut = {
//   borderRadius: { xl: '10px', lg: '8px', md: '7px', sm: '7px' },
//   border: '1px solid #E8E8E8',
//   background: '#fff',
//   width: { xl: '13%', lg: '12%', md: '11%' },
//   mx: 'auto',
//   height: { xl: '10px', lg: '8px', md: '7px' },
//   color: '#000',
//   textAlign: 'center',
//   fontFamily: 'Manrope',
//   fontSize: { xl: '16px', lg: '14px', md: '14px' },
//   fontStyle: 'normal',
//   fontWeight: 400,
//   display: 'flex',
//   justifyContent: 'space-between',
//   alignItems: 'center',
//   p: 2,
//   // position: 'absolute',
//   // bottom: { xl: 19, lg: 20, md: 25 },
//   cursor: 'pointer',
//   transition: 'background 0.1s, color 0.1s',
//   '&:hover': {
//     color: '#fff',
//     backgroundColor: 'primary.main',
//   },
// };

export const LogOut = {
  width: "73%",
  mx: "auto",
  background: "green",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "#000",
  textAlign: "center",
  fontFamily: "Manrope",
  fontSize: { xl: "16px", lg: "14px", md: "14px" },
  borderRadius: {
    xl: "10px",
    lg: "8px",
    md: "7px",
    sm: "7px",
  },
  p: 1,
  border: "1px solid #E8E8E8",
  background: "#fff",
  fontStyle: "normal",
  fontWeight: 400,
  cursor: "pointer",
  transition: "background 0.1s, color 0.1s",
  "&:hover": {
    color: "#fff",
    backgroundColor: "primary.main",
  },
};

export const newbatch = {
  borderRadius: "5px",
  background: "#F4F5F6",
  border: "none",
  width: "98px",
  mx: "auto",
  height: "27.012px",
  flexShrink: "0",
  color: "#A1A1A1",
  textAlign: "center",
  fontFamily: "Manrope",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "700",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  cursor: "pointer",
};

export const SendIcon = {
  paddingX: "2px",
  margin: 0,
  display: "flex",
  border: "none",
  background: "transparent",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  cursor: "pointer",
  transition: "0.3s",
  transform: "scale(1)",
  position: "relative",
  marginBottom: 15,
};

export const MessageViewStyle = {
  width: "100%",
  height: "100%",
  minHeight: { xl: "90vh", lg: "90vh", md: "90vh" },
  background: "#fff",
  borderRadius: "20px",
};
export const messageInputStyle = {
  width: { xl: "93%", lg: "92%", md: "89%", sm: "90%", xs: "90%" },
  borderRadius: "10px",
  height: { xl: "45px", lg: "40px", md: "35px" },
  mt: { xl: "4px", lg: "12px", md: "12px" },
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  px: "10px",
  mb: 2,
  border: "2px solid ",
  "&:hover": {
    border: "2px solid",
    borderColor: "primary.main",
  },
};
export const micStyle = {
  height: { xl: "40px", lg: "35px", md: "35px", sm: "30px", xs: "30px" },
  width: { xl: "40px", lg: "35px", md: "35px", sm: "30px", xs: "30px" },
  ml: -1,
  mt: { xl: "8px", lg: "16px", md: "12px", sm: "8px", xs: "8px" },
  textAlign: "center",
  // transition: '0.3s',
  position: "relative",
  cursor: "pointer",
  transition: "0.3s ease-in-out",
  boxShadow: 0,
  "&:hover": {
    // borderRadius: '40px',
    boxShadow: 6,
  },
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#091A32",
  // background: 'linear-gradient(180deg, #605BFF 33.96%, #255CFF 100%)',
};
export const que = {
  color: "#000",
  fontFamily: "Manrope",
  fontSize: { xl: "14px", lg: "14px", md: "12px", sm: "13px", xs: "13px" },
  fontStyle: "normal",
  fontWeight: "400",
  // lineHeight: '16px',
};

export const AIText = {
  color: "#191919",
  fontFamily: "Manrope",
  fontSize: { xl: "14px", lg: "14px", md: "12px", sm: "13px", xs: "13px" },
  fontStyle: "normal",
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  // mt: { xl: -3, lg: -3, md: -3, sm: 0, xs: 0.2 },
  "&:hover": {
    // fontWeight: 550,
    color: "primary.main",
  },
};

export const MulishFontTextStyle = {
  fontFamily: "poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  color: "#676767",
  textAlign: "center",
  lineHeight: "12px",
};

export const uploadText2 = {
  color: "primary.main",
  fontFamily: "Manrope",
  fontSize: { xl: "12px", lg: "10px", md: "8px", sm: "6px", xs: "6px" },
  fontStyle: "normal",
  fontWeight: 600,
  letterSpacing: -0.14,
  textAlign: "center",
};

export const batchNameText = {
  color: "#191919",
  textAlign: "center",
  fontFamily: "Manrope",
  fontSize: { xl: "18px", lg: "18px", md: "14px", sm: "12px", xs: "12px" },
  fontStyle: "normal",
  fontWeight: 600,
};

export const uploadedText = {
  color: "rgba(13, 13, 13, 0.68)",
  textAlign: "left",
  fontFamily: "Manrope",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  width: "80%",
  mx: "auto",
  mt: 2,
};

export const timeStaps = {
  // color: 'var(--Grey-Blue-80, #ADB8CC)',
  color: "#8D8D8D",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Manrope",
  fontSize: "8px",
  fontStyle: "normal",
  fontWeight: 900,
  mt: 1,
  wordWrap: "normal",
};

export const subtrashText = {
  color: "var(--black-100, #1C1C1C)",
  fontFamily: "Manrope",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
  ml: 0.2,
};

export const uploadSubText = {
  color: "#8D8D8D",
  textAlign: "center",
  fontFamily: "Manrope",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 400,
};

export const plusminus = {
  fontSize: "18px",
  color: "#191919",
  position: "relative",
  transition: "background 0.4s, color 0.4s",
  "&:hover::after": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    border: "2px solid #605BFF",
    transition: "all 2s ease",
    mt: 0.3,
  },
};

export const dropPdfStyle = {
  mt: 2,
  border: "2px solid",
  borderColor: "primary.main",
  "&:focus": {
    outline: "none",
  },
  "&:focus-within": {
    border: "2px dashed ##009FFC",
  },
  bgcolor: "background.paper",
  boxShadow: 5,
  borderRadius: {
    xl: "20px",
    lg: "20px",
    md: "16px",
    sm: "10px",
    xs: "10px",
  },
  gap: 0.5,
  p: { xl: 2, lg: 2, md: 2, sm: 1, xs: 1 },

  width: "80%",
  mx: "auto",
  // height: '60px',
  height: {
    xl: "100px",
    lg: "100px",
    md: "100px",
    sm: "70px",
    xs: "60px",
  },
};

export const dropFileList = {
  border: "2px solid ",
  borderColor: "primary.main",
  width: "80%",
  mx: "auto",
  borderRadius: "10px",
  p: 1,
  mt: 2,
  display: "flex",
  alignItems: "center",
  alignContent: "center",
  justifyContent: "space-between",
};

export const invoicebtn = {
  fontFamily: "Manrope",
  fontSize: { xl: "12px", lg: "10px", md: "8px", sm: "7px", xs: "7px" },
  fontWeight: 400,
  textAlign: "center",
  background: "rgba(96, 91, 255, 1)",
  borderRadius: "9px",
  p: 0.5,
  color: "#fff",
  width: { xl: "150px", lg: "150px", md: "150px", sm: "120px", xs: "120px" },
  textTransform: "none",
  "&:hover": {
    background: "rgba(96, 91, 255, 1)",
    color: "#fff",
  },
};

export const subemailtext = {
  fontFamily: "Manrope",
  fontSize: {
    xl: "1rem",
    lg: "1rem",
    md: "1rem",
    sm: "1rem",
    xs: "1rem",
  },
  fontWeight: 400,
  textAlign: "left",
  color: "rgba(23, 22, 24, 1)",
};

export const headHeading = {
  color: "#000",
  fontFamily: "Manrope",
  fontSize: { xl: "22px", lg: "22px", md: "20px", sm: "15px", xs: "15px" },
  fontStyle: "normal",
  fontWeight: 700,
  textAlign: "center",
};

export const subheading = {
  fontFamily: "Manrope",
  fontSize: { xl: "16px", lg: "16px", md: "14px", sm: "12px", xs: "12px" },
  fontWeight: 400,
  textAlign: "center",
  color: "rgba(28, 28, 28, 1)",
  mt: 0.8,
};

export const tabText = {
  color: "#000",
  fontFamily: "Manrope",
  fontSize: { xl: "18px", lg: "18px", md: "16px", sm: "15px", xs: "15px" },
  fontStyle: "normal",
  fontWeight: 400,
  textAlign: "left",
  p: 0.7,
  borderRadius: "8px",
  cursor: "pointer",
  transition: "background 0.4s, color 0.4s",
  "&:hover": {
    background: "rgba(9, 26, 50, 1)",
    color: "#fff",
  },
};

export const gridheaderText = {
  fontFamily: "Manrope",
  fontSize: { xl: "16px", lg: "16px", md: "14px", sm: "12px", xs: "12px" },
  fontWeight: 700,
  color: "rgba(3, 2, 41, 1)",
  textAlign: "center",
};

export const gridheaderSubText = {
  fontFamily: "Manrope",
  fontSize: { xl: "14px", lg: "14px", md: "14px", sm: "12px", xs: "12px" },
  fontWeight: 500,
  textAlign: "center",
  color: "rgba(23, 22, 24, 1)",
  mt: 0.5,
};

export const summerysubText = {
  fontFamily: "Manrope",
  fontSize: { xl: "14px", lg: "14px", md: "14px", sm: "12px", xs: "12px" },
  fontWeight: 400,
  textAlign: "left",
  color: "rgba(23, 22, 24, 1)",
};

export const categoriesBox = {
  mt: 1,
  width: "95%",
  mx: "auto",
  background: "rgba(247, 249, 251, 1)",
  p: 1,
  display: "flex",
  justifyContent: "space-between",
  borderRadius: "12px",
  fontFamily: "Manrope",
  fontSize: { xl: "16px", lg: "16px", md: "14px", sm: "10px", xs: "10px" },
  fontWeight: 500,
  textAlign: "left",
  cursor: "pointer",
  color: "rgba(62, 50, 50, 1)",
  "&:hover": {
    color: "rgba(255, 255, 255, 1)",
    background: "#091A32",
  },
};
export const planBoxButton = {
  mt: 1,
  width: "95%",
  mx: "auto",
  background: "rgba(247, 249, 251, 1)",
  p: { xl: 1, lg: 1, md: 1, sm: 0.7, xs: 0.7 },
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "12px",
  fontFamily: "Manrope",
  fontSize: { xl: "16px", lg: "16px", md: "14px", sm: "10px", xs: "10px" },
  fontWeight: 500,
  cursor: "pointer",
  color: "rgba(62, 50, 50, 1)",
  "&:hover": {
    color: "rgba(255, 255, 255, 1)",
    background: "#091A32",
  },
};

export const getintouchbtn = {
  fontFamily: "Manrope",
  fontSize: { xl: "11px", lg: "11px", md: "10px", sm: "8px", xs: "8px" },
  fontWeight: 400,
  color: "#fff",
  background: "rgba(96, 91, 255, 1)",
  p: 1,
  borderRadius: "15px",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "auto",
};

export const subheadingtabletext = {
  fontFamily: "Manrope",
  fontSize: { xl: "11px", lg: "11px", md: "10px", sm: "9px", xs: "9px" },
  fontWeight: 500,
  textAlign: "left",
  color: "rgba(133, 139, 160, 1)",
};

export const tableBodyText = {
  fontFamily: "Manrope",
  fontSize: { xl: "13px", lg: "13px", md: "12px", sm: "11px", xs: "11px" },
  fontWeight: 700,
  textAlign: "left",
  color: "rgba(37, 36, 48, 1)",
};

export const gridheadermainText = {
  fontFamily: "Manrope",
  fontSize: { xl: "22px", lg: "22px", md: "16px", sm: "10px", xs: "10px" },
  fontWeight: 700,
  textAlign: "left",
  color: "rgba(37, 36, 48, 1)",
  textAlign: "center",
  width: "auto",
};

export const planBtn = {
  mt: 0.5,
  background: "rgba(3, 2, 41, 1)",
  fontFamily: "Manrope",
  fontSize: { xl: "14px", lg: "14px", md: "12px", sm: "10px", xs: "10px" },
  fontWeight: 500,
  textAlign: "center",
  color: "rgba(230, 233, 245, 1)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  p: 1,
  borderRadius: "8px",
  cursor: "pointer",
  "&:hover": {
    background: "rgba(104, 133, 140, 1)",
  },
};

export const tableBodySubText = {
  // fontFamily: 'Manrope',
  fontFamily: "Manrope",
  fontSize: { xl: "11px", lg: "11px", md: "10px", sm: "9px", xs: "9px" },
  fontWeight: 600,
  textAlign: "center",
  color: "rgba(37, 36, 48, 1)",
  mt: 0.2,
};

export const boxMainText = {
  fontFamily: "Manrope",
  fontSize: { xl: "17px", lg: "17px", md: "16px", sm: "14px", xs: "14px" },
  fontWeight: 700,
  textAlign: "left",
  color: "rgba(0, 0, 0, 1)",
};

export const boxSubText = {
  fontFamily: "Manrope",
  fontSize: { xl: "14px", lg: "14px", md: "12px", sm: "10px", xs: "10px" },
  fontWeight: 400,
  textAlign: "left",
  color: "rgba(0, 0, 0, 1)",
};

export const blogmainText = {
  fontFamily: "Manrope",
  fontSize: { xl: "12px", lg: "12px", md: "12px", sm: "10px", xs: "10px" },
  fontWeight: 600,
  textAlign: "left",
  color: "rgba(62, 50, 50, 1)",
  textAlign: "left",
  width: "100%",
  whiteSpace: "normal",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  mt: 0.5,
};

export const footercolor = {
  fontFamily: "Manrope",
  fontSize: { xl: "15px", lg: "15px", md: "14px", sm: "12px", xs: "12px" },
  fontWeight: 400,
  textAlign: { xl: "left", lg: "left", md: "left", sm: "center", xs: "center" },
  lineHeight: "30px",
};

export const footersubText = {
  fontFamily: "Manrope",
  fontSize: { xl: "14px", lg: "14px", md: "14px", sm: "12px", xs: "12px" },
  fontWeight: 400,
  textAlign: { xl: "left", lg: "left", md: "left", sm: "center", xs: "center" },
  color: "rgba(30, 40, 51, 1)",
  lineHeight: "30px",
  cursor: "pointer",
  "&:hover": {
    color: "#9DAAAD",
  },
};

export const footerText = {
  fontFamily: "Manrope",
  fontSize: { xl: "15px", lg: "15px", md: "14px", sm: "11px", xs: "11px" },
  fontWeight: 500,
  textAlign: { xl: "left", lg: "left", md: "left", sm: "center", xs: "center" },
  color: "rgba(30, 40, 51, 1)",
  mt: 0.5,
};

export const footerMainSection = {
  position: "relative",
  borderTop: "1px solid rgba(30, 40, 51, 0.07)",
  borderBottom: "1px solid rgba(30, 40, 51, 0.07)",
  width: "80%",
  height: { xl: "150px", lg: "150px", md: "150px", sm: "auto", xs: "auto" },
  mx: "auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  p: 0.5,
  mt: { xl: 5, lg: 5, md: 5, sm: 2, xs: 2 },
};

export const IconStyle = {
  height: "auto",
  transition: "0.3s",
  transform: "scale(1)",
  position: "relative",
  width: {
    xl: "20px",
    lg: "20px",
    md: "20px",
    sm: "15px",
    xs: "15px",
  },
  ml: "auto",
  cursor: "pointer",
};

export const messagesText = {
  marginBottom: "10px",
  position: "relative",
  display: "inline-block",
  width: "auto",
  minWidth: "130px",
  maxWidth: "60%",
  mx: "auto",
  // p: { xl: '6px', lg: '6px', md: '6px', sm: '4px', xs: '4px' },
  px: 1,
  borderRadius: "7.379px",
  border: "1px solid #F0F0F0",
  fontFamily: "Manrope",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: "400",
  letterSpacing: 0.9,
  textAlign: "left",
  marginTop: "10px",
  boxShadow: "0px 1.47586px 7.37931px 0px rgba(38, 51, 77, 0.05)",
  // display: 'flex', // Use flex display
  // flexDirection: 'column',
};

export const pdfCountPageNumber = {
  color: "black",
  mr: "auto",
  borderRadius: 2,
  p: 0.2,
  border: "1px solid var(--Grey-04, #CDD3D8)",
  color: "rgba(36, 38, 52, 0.80)",
  textAlign: "center",
  fontFamily: "Manrope",
  fontSize: "11px",
  fontStyle: "normal",
  fontWeight: 600,
  background: "#fff",
};

export const scrollTagBox = {
  py: 1,
  height: "auto",
  width: "100%",
  mx: "auto",
  maxWidth: "90%",
  overflowX: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "10px",
  whiteSpace: "nowrap",
  cursor: "pointer",
  pb: 0.2,
};

export const tagText = {
  width: "100%",
  background: "rgba(193, 220, 226, 0.18)",
  p: 0.7,
  borderRadius: "11px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Manrope",
  fontSize: { xl: "11px", lg: "11px", md: "10px", sm: "9px", xs: "9px" },
  fontWeight: 400,
  letterSpacing: "0.02em",
  textAlign: "center",
  color: "#000",
  border: "1px solid #F0F0F0",
};

export const sourceLink = {
  // border: '1px solid rgba(205, 211, 216, 1)',
  // background: '#fff',
  // padding: '5px 10px',
  // borderRadius: '5px',
  cursor: "pointer",
  minWidth: "100px",
  maxWidth: "100px",
  textAlign: "center",
  color: "blue",
  fontSize: "10px",
  borderBottom: "1px solid blue",
  py: 0.2,
  fontSize: "12px",
};

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "52%",
  transform: "translate(-50%, -50%)",
  background: "#fff",
  borderRadius: "15px",
  border: "2px solid rgba(241, 243, 246, 1)",
  p: 2,
  mt: 2,
  zIndex: 999999,
  width: "80%",
  height: "100%",
  maxHeight: "85vh",
  display: "flex",
  flexWrap: "wrap",
  overflowY: "auto",
};

export const opt = {
  height: "41px",
  minWidth: "100%",
  borderRadius: "12px",
  border: "1px solid rgba(241, 243, 246, 1)",
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 500,
  mt: 0.4,
  fontSize: "14px",
  color: "#AFAFAF",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomePage from '../HomeChat/HomePage';
import Login from '../LogIn/SignIn';
import SignUp from '../LogIn/SignUp';
import Profile from '../Profile/Profile';
import Header from '../HeaderUi/Header';
import MainChatFile from '../ChatPdf/MainChatFile';
import OurServices from '../HeaderComponents/OurServices';
import FaqPage from '../HeaderComponents/FaqPage';
import ContactUs from '../HeaderComponents/ContactUs';
import Plans from '../HeaderComponents/Plans';
import ContentCoverage from '../HeaderComponents/ContentCoverage';
import NewHome from '../HomeChat/NewHome';
import PrivacyPolicy from '../HeaderComponents/PrivacyPolicy';
import Termsandcondition from '../HeaderComponents/Termsandcondition';
import ViewShareChat from '../ChatPdf/ViewShareChat';
import ViewBlog from '../HomeChat/ViewBlog';
import PrivateRoute from './PrivateRoute';

const routesData = createBrowserRouter(
  [
    {
      path: '/',
      element: <NewHome />,
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/signup',
      element: <SignUp />,
    },
    {
      path: '/profile',
      element: <PrivateRoute element={<Profile />} />,
    },
    {
      path: '/header',
      element: <Header />,
    },
    {
      path: '/services',
      element: <OurServices />,
    },
    {
      path: '/faq',
      element: <FaqPage />,
    },
    {
      path: '/contact',
      element: <ContactUs />,
    },
    {
      path: '/privacypolicy',
      element: <PrivacyPolicy />,
    },
    {
      path: '/termsandcondition',
      element: <Termsandcondition />,
    },
    {
      path: '/plan',
      element: <Plans />,
    },
    {
      path: '/content',
      element: <ContentCoverage />,
    },
    {
      path: '/mainchat/:id',
      element: <PrivateRoute element={<MainChatFile />} />,
    },
    {
      path: '/viewsharechat/:id',
      element: <PrivateRoute element={<ViewShareChat />} />,
    },
    {
      path: '/viewblog',
      element: <PrivateRoute element={<ViewBlog />} />,
    },
  ],
  {
    basename: `/`,
  }
);

const MainRouter = ({ children }) => {
  return (
    <React.Fragment>
      <RouterProvider router={routesData}>{children}</RouterProvider>
    </React.Fragment>
  );
};

export default MainRouter;

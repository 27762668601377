import axios from "axios";
import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import MainRouter from "./Components/Router/Router";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
// axios.defaults.baseURL = 'https://backend-aal-v2.unada.in/';
// axios.defaults.baseURL = 'http://192.168.29.5:9000/';
// axios.defaults.baseURL = 'https://diffy-v2.unada.in/';
// axios.defaults.baseURL = 'https://aalv2-prod.unada.in/';
// axios.defaults.baseURL = 'https://aalv2-dev.unada.in/';
axios.defaults.baseURL = "https://aalv2-dev.unada.in/";
// axios.defaults.baseURL = 'https://5r8ll7zq-9000.inc1.devtunnels.ms/';
// axios.defaults.baseURL = 'http://localhost:9000/';
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.data?.message && error.response.status === 400)
      toast.error(error?.response?.data?.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    return Promise.reject(error);
  }
);
const theme = createTheme({
  palette: {
    primary: {
      main: "#68858C",
    },
    secondary: {
      main: "#091A32",
    },
  },
});
function App() {
  return (
    <>
      <ToastContainer />
      <ThemeProvider theme={theme}>
        <MainRouter />
      </ThemeProvider>
    </>
  );
}

export default App;

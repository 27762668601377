import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { GetTokenFunc } from "../utils/GetToken";

export const useGetWallet = () => {
  return useQuery({
    queryKey: ["userWallet"],
    queryFn: async () => {
      return await axios.get("api/v1/wallet/get-user-wallet", {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};
export const useGetWalletHistroy = () => {
  return useQuery({
    queryKey: ["userWalletHistory"],
    queryFn: async () => {
      return await axios.get("api/v1/wallet/get-transactions-for-one-user", {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};
export const useApplyCoupon = () => {
  return useMutation({
    mutationKey: ["applycoupon"],
    mutationFn: async (data) => {
      return await axios.post("api/v1/coupon/apply-coupon", data, {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};

import React, { useRef } from 'react';
import { Paper, Box, Typography, Button } from '@mui/material';
import * as Styles from '../../Common/Styles.js';
import forwardicon from '../../Assets/mainPage/forwardicon.svg';
import backwardicon from '../../Assets/mainPage/backwardicon.svg';
import slideone from '../../Assets/mainPage/slideone.svg';
import slidetwo from '../../Assets/mainPage/slidetwo.svg';
import Fade from 'react-reveal/Fade';
const NewsComponent = () => {
  const scrollBoxRef = useRef(null);
  const scrollForward = () => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollBy({ left: 500, behavior: 'smooth' }); // Adjust the scroll amount as needed
    }
  };

  const scrollBackward = () => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollBy({ left: -500, behavior: 'smooth' }); // Adjust the scroll amount as needed
    }
  };
  return (
    <div>
      <Box
        sx={{
          width: '100%',
          // background: '#CEE8EE',
          background: 'rgba(247, 249, 251, 1)',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          mt: 10,
          p: 2,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            width: '80%',
            mx: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <Fade top duration={500}>
            <Typography sx={Styles.boxMainText}>Latest News</Typography>
          </Fade>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <Box
              component={'img'}
              src={backwardicon}
              sx={{
                height: 'auto',
                width: 'auto',
                cursor: 'pointer',
                boxShadow: 3,
                transition: 'transform 0.3s, box-shadow 0.3s',
                borderRadius: '10px',
                '&:hover': {
                  transform: 'scale(1.02)',
                  boxShadow: 6,
                },
              }}
              onClick={scrollBackward}
            />
            <Box
              component={'img'}
              src={forwardicon}
              sx={{
                height: 'auto',
                width: 'auto',
                cursor: 'pointer',
                borderRadius: '10px',
                boxShadow: 3,
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  // transform: 'scale(1.02)',
                  boxShadow: 6,
                },
              }}
              onClick={scrollForward}
            />
          </Box>
        </Box>
        <Box sx={scrollingImg} ref={scrollBoxRef}>
          {[1, 2, 3, 4, 5, 6].map((item, index) => (
            <>
              <Fade top duration={500}>
                <Box
                  component={'img'}
                  src={slideone}
                  alt=""
                  sx={bigimageStyles}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 3,
                  }}
                >
                  <Box
                    component={'img'}
                    src={slidetwo}
                    alt=""
                    sx={smallimgStyle}
                  />{' '}
                  <Box
                    component={'img'}
                    src={slidetwo}
                    alt=""
                    sx={smallimgStyle}
                  />
                </Box>
              </Fade>
            </>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default NewsComponent;

const mainBoxesStyles = {
  width: '90%',
  mx: 'auto',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  mt: 10,
  gap: '10px',
};

const insideBoxStyle = {
  background: 'rgba(247, 249, 251, 1)',
  borderRadius: '10px',
  p: 3,
  maxWidth: '220px',
  position: 'relative',
};

const scrollingImg = {
  mt: 2,
  height: 'auto',
  width: '100%',
  mx: 'auto',
  maxWidth: { xl: '80%', lg: '80%', md: '80%', sm: '100%', xs: '100%' },
  overflowX: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '20px',
  whiteSpace: 'nowrap',
};

const bigimageStyles = {
  maxHeight: {
    xl: '380px',
    lg: '380px',
    md: '340px',
    sm: '210px',
    xs: '210px',
  },
  ml: 1,
  Width: 'auto',
  height: 'auto',
  borderRadius: '10px',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.01)',
  },
  // transition: 'border-color 0.5s ease-in-out',
  // border: '2px solid transparent',
  // borderRadius: '12px',
  // '&:hover': {
  //   borderColor: 'primary.main', // Change this color as needed
  // },
};

const smallimgStyle = {
  maxHeight: {
    xl: '200px',
    lg: '200px',
    md: '180px',
    sm: '120px',
    xs: '120px',
  },
  width: 'auto',
  height: 'auto',
  borderRadius: '10px',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.01)',
  },
};

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Avatar,
  Tooltip,
  MenuItem,
  Menu,
  IconButton,
  Divider,
} from '@mui/material';
import menuHorz from '../../Assets/Header/menuHorz.png';
import phone from '../../Assets/Header/phone.png';
import pricetag from '../../Assets/Header/pricetag.png';
import setting from '../../Assets/Header/setting.png';
import help from '../../Assets/Header/help.png';
import Mask from '../../Assets/ProfileImg/Mask.png';
import { Link, useNavigate } from 'react-router-dom';
import {
  stringAvatar,
  stringToColor,
} from '../../Components/avatar/stringAvatar';
import {
  useGetUserProfile,
  useUpdateUserProfile,
} from '../../Hooks/ProfileHooks';
const HeaderPhone = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navStyle = {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    position: 'absolute',
    top: '3.5rem',
    right: isMenuOpen ? '0' : '100%',
    backgroundColor: '#fff',
    width: '100%',
    transition: 'right 1s',
    height: '90vh',
    zIndex: 10000,
  };

  // Get Users Profile Data
  const {
    data: profileData,
    isLoading: profileLoading,
    error: profileError,
    refetch: profileDataRefetch,
  } = useGetUserProfile();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Box
        sx={{
          display: {
            xl: 'none',
            lg: 'none',
            md: 'none',
            sm: 'flex',
            xs: 'flex',
          },
        }}
      >
        <header style={{ transition: 'right 2s' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              mx: 'auto',
              minWidth: '95vw',
            }}
          >
            {window.location.pathname === '/profile' ? (
              <>
                <div onClick={toggleMenu}>
                  <Box
                    component={'img'}
                    src={menuHorz}
                    alt=""
                    sx={{
                      height: '33px',
                      width: '33px',
                      cursor: 'pointer',
                      m: 1,
                      transition: '0.5s',
                      transform: isMenuOpen ? 'rotate(90deg)' : null,
                    }}
                  />
                </div>
                <Box
                  sx={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={askText}>
                    Ask &nbsp;
                    <Box component={'span'} sx={antText}>
                      Anything
                    </Box>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    onClick={() => {
                      navigate('/profile');
                    }}
                    component={'img'}
                    src={Mask}
                    alt=""
                    sx={{ height: 'auto', width: '30px' }}
                  />
                </Box>
              </>
            ) : (
              <>
                <div onClick={toggleMenu}>
                  <Box
                    component={'img'}
                    src={menuHorz}
                    alt=""
                    sx={{
                      height: '33px',
                      width: '33px',
                      cursor: 'pointer',
                      m: 1,
                      transition: '0.5s',
                      transform: isMenuOpen ? 'rotate(90deg)' : null,
                    }}
                  />
                </div>

                <Box
                  sx={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    navigate('/profile');
                  }}
                >
                  <Avatar
                    {...stringAvatar(profileData?.data?.data?.fullName)}
                  />
                  {/* <Box
                    onClick={() => {
                      navigate('/profile');
                    }}
                    component={'img'}
                    src={Mask}
                    alt=""
                    sx={{ height: 'auto', width: '30px' }}
                  /> */}
                </Box>
              </>
            )}
          </Box>
          <nav style={navStyle}>
            <ul>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  mx: 'auto',
                  gap: '40px',
                  flexDirection: 'column',
                }}
              >
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <Box sx={adjHeaderTextStyle}>
                    <Typography sx={headerTextStyle}>Home</Typography>
                  </Box>
                </Link>
                <Link to="/contact" style={{ textDecoration: 'none' }}>
                  <Box sx={adjHeaderTextStyle}>
                    <Typography sx={headerTextStyle}>Contact</Typography>
                  </Box>
                </Link>
                <Link to="/plan" style={{ textDecoration: 'none' }}>
                  <Box sx={adjHeaderTextStyle}>
                    <Typography sx={headerTextStyle}>Pricing</Typography>
                  </Box>
                </Link>
                <Link to="/services" style={{ textDecoration: 'none' }}>
                  <Box sx={adjHeaderTextStyle}>
                    <Typography sx={headerTextStyle}>Services</Typography>
                  </Box>
                </Link>
                <Link to="/faq" style={{ textDecoration: 'none' }}>
                  <Box sx={adjHeaderTextStyle}>
                    <Typography sx={headerTextStyle}>FAQs</Typography>
                  </Box>
                </Link>
              </Box>
            </ul>
          </nav>
        </header>
      </Box>
    </div>
  );
};

export default HeaderPhone;

const headerTextStyle = {
  color: '#191919',
  fontFamily: 'Lato',
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  textAlign: 'left',
};

const askText = {
  color: '#19A8FC',
  fontFamily: 'Lato',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
};
const antText = {
  color: 'rgba(0, 0, 0, 1)',
  fontFamily: 'Lato',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
};

const adjHeaderTextStyle = {
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
};
